<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div>
        <validation-observer ref="informationDocument">
            <b-row style="display:block;">
                <b-col cols="6">
                <b-form-group
                    label="Title"
                    label-for="h-Title"
                    label-cols-md="2"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Title"
                        rules="required"
                    >
                        <b-form-input
                            id="h-Title"
                            type="text"
                            placeholder="Title"
                            maxlength="50"
                            v-model="title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col cols="6">
                <b-form-group
                    label="Description"
                    label-for="h-Description"
                    label-cols-md="2"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                    >
                        <b-form-textarea
                            id="h-Description"
                            maxlength="500"
                            placeholder="Description"
                            v-model="discription"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col cols="6">
                <b-form-group
                    label="Attachment"
                    label-for="h-Attachment"
                    label-cols-md="2"
                >           
                    <validation-provider
                        #default="{ errors }"
                        name="Attachment"
                        rules="required"
                    >
                        <b-form-file
                            v-model="attachment"
                            :disabled="onlyRead || isSpinner"
                            @input="attachmentUpload"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div class="mt-1">
                        <strong>{{ attachment != null ? `Selected file: ${attachment.name}` : '' }}</strong>
                        <img v-if="attachment" src="../../../assets/images/erflog/Delete.png" @click.prevent="deleteButtonCalled" alt="attachment" style="width: 20px; height: 20px; margin-left: 10px;"/>
                    </div>
                    <small class="text-danger">{{ errorMessage }}</small>
                </b-form-group>
                </b-col>
                <!-- <b-col md="3">
                    <b-form-group label="Document should show to student?">
                    <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                        <b-form-radio
                            v-model="documentShowToStudent"
                            plain
                            name="doc"
                            value="Enable"
                        >
                            Enable
                        </b-form-radio>
                        <b-form-radio
                            v-model="documentShowToStudent"
                            plain
                            name="doc"
                            value="Disable"
                        >
                            Disable
                        </b-form-radio>
                    </div>
                    </b-form-group>
                </b-col> -->
                <b-col cols="8">
                    <b-form-group
                        label="Should the student see the document?"
                        label-for="h-Receiver"
                        label-cols-md="2"
                    >
                        <div class="demo-inline-spacing">
                            <b-form-checkbox
                                v-model="documentShowToStudent"
                            >
                                Yes
                            </b-form-checkbox>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="sendBtn" style="text-align:left;">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="addDocument"
                    :disabled="onlyRead || isSpinner"
                >
                Create
                </b-button>
                <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="$router.push({name: 'information-document'})"
                      :disabled="onlyRead || isSpinner"
                  >
                  Cancel
                  </b-button>
            </div>
        </validation-observer>
        </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { required,min,max } from 'vee-validate/dist/rules'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormRadio,BFormFile,BFormSelect,BSpinner,BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
extend('required',required);
extend('min',min);
extend('max',max);
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormRadio,
        BFormFile,
        BFormSelect,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            attachment: null,
            title:"",
            discription: "",
            options: [],
            errorMessage: "",
            isSpinner: false,
            onlyRead:false,
            documentShowToStudent: false,
        }
    },
    created() {
        this.$root.$on('stopSpinner',()=>{
            this.isSpinner = false;
        });
        
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Information Document"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
    },
    methods: {
        deleteButtonCalled() {
            if(this.isSpinner) {
                return;
            }
            this.attachment = null;
            this.errorMessage = '';
        },
        addDocument(){
            try {             
                var self = this;
                self.$refs.informationDocument.validate().then((valid) => {
                    if (valid && self.errorMessage == '') {
                        this.isSpinner = true;
                        uploadImageFile.uploadImageFile('informationDocument',this.attachment.name,this.attachment,(resp)=>{
                            let obj = {
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                title: this.title,
                                discription: this.discription,
                                attachment: resp.imageUrl,
                                attachmentName: resp.imageName,
                                isdocenabled: this.documentShowToStudent,
                                isDeleted: false,
                            }
                            let doc = db.collection(dbCollections.INFORMATIONDOCUMENT).doc();
                            obj.id = doc.id;
                            doc.set(obj).then(()=>{
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'Information document created successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',                               
                                    },
                                });
                                this.$router.push({name: 'information-document'});
                                this.isSpinner = false;
                            }).catch((error)=>{
                                this.isSpinner = false;
                                console.error(error);
                            })
                        });
                    } else {
                        this.isSpinner = false;
                    }
                });
            } catch (error) {
                this.isSpinner = false;
                console.error(error);
            }
        },
        attachmentUpload(){
            try {
                this.errorMessage = "";
                if(this.attachment == null) {
                    return;
                }
                let extension = this.attachment.name.split('.').pop();
                let allowedExtensions = ['jpg','png','jpeg','pdf','doc','docx','xls','xlsx','txt','ppt','pptx'];
                // if (!(this.attachment.type == 'image/png' || this.attachment.type == 'image/jpg' || this.attachment.type == 'image/jpeg'||this.attachment.type == 'jpg' || this.attachment.type =='png' ||this.attachment.type == 'jpeg' || this.attachment.type == 'application/pdf')) {
                if (!allowedExtensions.includes(extension.toLowerCase())) {
                    this.errorMessage = 'Uploaded file is not a valid. Allowed files are ' + allowedExtensions.join(', ');
                }
                else if (this.attachment.size >= 20000000 ) {
                    this.errorMessage = 'File should be less than 20 mb'
                } 
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<style>

</style>